import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import GhostButton from '../GhostButton/GhostButton';
import StyledTableBodyRow from './TableBodyRowUserAdmin.styles';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import type { ChangeCostType, UserType } from '../../types/userTypes';
import userThunk from '../../store/user/userThunk';
import ChangeCostModal from '../ChangeCostModal/ChangeCostModal';
import { formattingDate } from '../../utils/formattingDateHelper';
import config from '../../config';

type PropsType = {
  item: UserType;
  className?: string;
};

const initialValues = {
  userId: null,
  email: '',
  rowCost: 0,
  llcCost: 0,
  isBlacklistFree: false,
  isSubscriptionFree: false,
};

const TableBodyRowUserAdmin: React.FC<PropsType> = (props) => {
  const { item, className = '' } = props;
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.userSlice.filter);
  const [isOpenChangeCostModal, setIsOpenChangeCostModal] = useState(false);
  const [rowCostInfo, setRowCostInfo] = useState<ChangeCostType>(initialValues);

  const correctDisplayInfo = (info: number | string | null) => (info || '-');

  const onChangeCost = async (
    rowCost: number, llcCost: number, isBlacklistFree: boolean, isSubscriptionFree: boolean,
  ) => {
    try {
      const newRowCost: ChangeCostType = {
        userId: rowCostInfo.userId,
        email: rowCostInfo.email,
        rowCost,
        llcCost,
        isBlacklistFree,
        isSubscriptionFree,
      };

      await dispatch(userThunk.changeCost(newRowCost)).unwrap();
      await dispatch(userThunk.getAdminUsers(filter)).unwrap();
    } catch (error) {
      toast.error(error);
    } finally {
      onClickCancelChangeRowCostModal();
    }
  };

  const onOpenChangeRowCostModal = (item: UserType) => {
    setIsOpenChangeCostModal(true);
    setRowCostInfo({
      userId: item.userId,
      rowCost: item.rowCost,
      llcCost: item.llcCost,
      email: item.email,
      isBlacklistFree: item.isBlacklistFree,
      isSubscriptionFree: item.subscription?.type.toLowerCase() === config.adminFreeSubscription,
    });
  };

  const onClickCancelChangeRowCostModal = () => {
    setIsOpenChangeCostModal(false);
    setRowCostInfo(initialValues);
  };

  const orderValueArray = useMemo(() => {
    return [
      { content: item.userId },

      { content: correctDisplayInfo(item.fullName) },

      { content: item.email },

      { content: correctDisplayInfo(item.phone) },

      { content: formattingDate(item.createdAt) },

      { content: `$${item.llcCost.toFixed(3)}` },

      { content: `$${item.rowCost.toFixed(3)}` },

      { content: `$${item.balance.toFixed(2)}` },

      {
        content: (
          <GhostButton className="secondary">
            <EditIcon onClick={() => onOpenChangeRowCostModal(item)} />
          </GhostButton>
        ),
      },
    ];
  }, [item]);

  return (
    <>
      <StyledTableBodyRow className={className}>
        {orderValueArray.map((item, index) => (
          <td key={index}>
            <div>
              {item.content}
            </div>
          </td>
        ))}
      </StyledTableBodyRow>
      {
        isOpenChangeCostModal ? (
          <tr>
            <td>
              <ChangeCostModal
                onClick={onChangeCost}
                onCloseModal={onClickCancelChangeRowCostModal}
                rowCostInfo={rowCostInfo}
                isChangeSubscriptiopn={
                  !item.subscription?.id ||
                  item.subscription?.type.toLowerCase() === config.adminFreeSubscription
                }
              />
            </td>
          </tr>
        ) : null
      }
    </>
  );
};

export default TableBodyRowUserAdmin;
