import React, { useState } from 'react';
import { Slider, Box, Typography, Button } from '@mui/material';

const sliderMarks = [
  { value: 0, label: '0' },
  { value: 2500, label: '2500' },
  { value: 5000, label: '5000' },
  { value: 7500, label: '7500' },
  { value: 10000, label: '10000' },
];

const getPackageLabel = (value: number): string => {
  if (value <= 2750) return 'Starter Plan';
  if (value <= 6000) return 'Growth Plan';
  return 'Business Plan';
};

const PlanSlider: React.FC = () => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  return (
    <Box
      sx={{
        width: { xs: '100%', lg: '80%' },
        textAlign: 'center',
        bgcolor: 'white',
        px: 5,
        py: 2,
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{ marginBottom: '1rem', fontWeight: 'bold' }}
      >
        How many records are you planning on skipping this month?
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Slider
          value={sliderValue}
          onChange={(e, newValue) => setSliderValue(newValue as number)}
          min={0}
          max={10000}
          step={1}
          marks={sliderMarks}
          valueLabelDisplay="auto"
        />
      </Box>

      <Button
        variant="outlined"
        sx={{
          fontWeight: 'bold',
          marginTop: '1rem',
          padding: '0.5rem 2rem',
        }}
      >
        {getPackageLabel(sliderValue)}
      </Button>
    </Box>
  );
};

export default PlanSlider;
