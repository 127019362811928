const config = {
  domainName: process.env.REACT_APP_DOMAIN,
  staticAppDomain: process.env.REACT_APP_STATIC_APP_DOMAIN,
  firstPromoterUrl: process.env.REACT_APP_FIRST_PROMOTER_URL,
  stripeCustomerPortalUrl: process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL,
  subscriptionPlans: {
    // free: {
    //   id: null,
    //   name: 'Base',
    //   features: ['10 cents per skip', '20 cents per skip LLC cost'],
    //   price: 'Free',
    // },
    starter: {
      id: process.env.REACT_APP_STRIPE_STARTER_SUBSCRIPTION,
      name: 'Starter',
      features: ['6 cents per skip', '15 cents per skip LLC cost'],
      price: '24/month',
    },
    growth: {
      id: process.env.REACT_APP_STRIPE_GROWTH_SUBSCRIPTION,
      name: 'Growth',
      features: ['4 cents per skip', '12 cents per skip LLC cost'],
      price: '79/month',
    },
    business: {
      id: process.env.REACT_APP_STRIPE_BUSINESS_SUBSCRIPTION,
      name: 'Business',
      features: ['2.5 cents per skip', '12 cents per skip LLC cost'],
      price: '199/month',
    },
  },
  promoCodePlans: {
    GETSKOOLED: {
      id: process.env.REACT_APP_STRIPE_GETSKOOLED_SUBSCRIPTION,
      name: 'Getskooled',
      features: ['2 cents per skip', '12 cents per skip LLC cost'],
      price: '150/month',
    },
    EBCC: {
      id: process.env.REACT_APP_STRIPE_EBCC_SUBSCRIPTION,
      name: 'EBCC',
      features: ['2 cents per skip', '12 cents per skip LLC cost'],
      price: '99/month',
    },
  },
  freeBlackListSubscriptions: ['business', 'getskooled', 'growth', 'ebcc'],
  freeEmailSubscriptions: ['business', 'getskooled'],
  adminFreeSubscription: 'freebyadmin',
  emailPricePerRecord: 0.01,
  blacklistPricePerRecord: 0.01,
  googleAnalytics: {
    enabled: process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true',
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  },
  firstTimeCoupon: { code: 'FIRSTTIME', upto: 1000, discount: 0.25 },
  stripProducts: [
    { id: process.env.REACT_APP_STRIPE_STARTER_PRODUCT, name: 'Starter' },
    { id: process.env.REACT_APP_STRIPE_GROWTH_PRODUCT, name: 'Growth' },
    { id: process.env.REACT_APP_STRIPE_BUSINESS_PRODUCT, name: 'Business' },
    { id: process.env.REACT_APP_STRIPE_GETSKOOLED_PRODUCT, name: 'Getskooled' },
  ],
};

export default config;
