import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Modal, Box, Button, Grid } from '@mui/material';
import Input from '../Input/Input';
import { SkipTraceFileHeaders } from '../../utils/skipTraceFileHeaders';

interface IFormValues {
  [key: string]: string;
}

function createFieldList(headers: string[]) {
  const getType = (field: string) => {
    if (field.toLowerCase().includes('email')) { return 'email'; }
    if (field.toLowerCase().includes('phone') ||
      field.toLowerCase().includes('code') ||
      field.toLowerCase().includes('zip')
    ) {
      return 'number';
    }
    return 'text';
  };

  return headers.map((header) => ({
    name: header,
    title: header.length <= 3 ? header : header.replace(/([A-Z])/g, ' $1').trim(),
    type: getType(header),
    required: true,
  }));
}

type PropsType = {
  skipTraceType: string;
  onCloseModal: () => void;
  onUploadFile: (values: IFormValues) => void;
};

const AddSkipTraceModal: React.FC<PropsType> = (props) => {
  const fieldsList = createFieldList(SkipTraceFileHeaders[props.skipTraceType]);

  const formik = useFormik({
    initialValues: fieldsList
      .reduce<IFormValues>((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {}),
    onSubmit: (values, { resetForm }) => {
      try {
        props.onUploadFile(values);
      } catch (error) {
        toast.error(error);
      } finally {
        resetForm();
        props.onCloseModal();
      }
    },
  });

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '833px',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    maxHeight: '80vh',
    overflow: 'scroll',
    borderRadius: 3,
  };

  return (
    <Modal open onClose={props.onCloseModal}>
      <Box sx={styles}>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <h3>{props.skipTraceType}</h3>
        </Box>
        <Grid container spacing={2} my={2}>
          {fieldsList.map((field) => (
            <Grid item key={field.name} xs={12} sm={6}>
              <Input
                type={field.type}
                nameInput={field.name}
                title={field.title}
                placeHolder={`Enter ${field.title}`}
                onChange={formik.handleChange}
                value={formik.values[field.name]}
                error={formik.errors[field.name]}
                isTouched={formik.touched[field.name]}
                onBlur={formik.handleBlur}
                required={field.required}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button sx={{ px: 2, py: 1, borderRadius: 2 }} type="button" onClick={props.onCloseModal}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" sx={{ px: 3, py: 1, borderRadius: 2 }}>
            Save
          </Button>
        </Box>
      </Box>
      </Box>
    </Modal>
  );
};

export default AddSkipTraceModal;
