import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '../Button';
import GhostButton from '../GhostButton';
import Modal from '../Modal';
import Input from '../Input/Input';
import { rowCostSchema } from '../../types/validationSchemes';
import type { ChangeCostType } from '../../types/userTypes';

type PropsType = {
  onClick?: (
    rowCost: number, llcCost: number, isBlacklistFree: boolean, isSubscriptionFree:boolean
  ) => void;
  className?: string;
  onCloseModal?: () => void;
  isChangeSubscriptiopn?: boolean;
  rowCostInfo: ChangeCostType;
};

const ChangeCostModal: React.FC<PropsType> = (props) => {
  const currentEmail = props.rowCostInfo.email || '';
  const formik = useFormik({
    initialValues: {
      rowCost: props.rowCostInfo.rowCost || 0,
      llcCost: props.rowCostInfo.llcCost || 0,
      isBlacklistFree: props.rowCostInfo.isBlacklistFree || false,
      isSubscriptionFree: props.rowCostInfo.isSubscriptionFree || false,
    },
    validationSchema: rowCostSchema,
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        if (props?.onClick) {
          props?.onClick(
            values.rowCost,
            values.llcCost,
            values.isBlacklistFree,
            values.isSubscriptionFree,
          );
        }
        toast.success(`The price of ${currentEmail} has been changed`);
      } catch (err) {
        toast.error(err);
      }
    },
  });

  return (
    <Modal>
      <div className="styled-modal__container__form__input">
        <h3>Price for</h3>
        <h1>{currentEmail}</h1>
      </div>
      <h1 className="styled-modal__container__text">You can change the price if necessary</h1>
      <form onSubmit={formik.handleSubmit} className="styled-modal__container__form">
        <div className="styled-modal__container__form__input">
          <div>
            <Input
              nameInput="llcCost"
              title="LLC Skip Rate per record"
              placeHolder="$"
              type="number"
              step="0.001"
              min="0"
              onChange={formik.handleChange}
              value={formik.values.llcCost}
              error={formik.errors.llcCost}
              isTouched={formik.touched.llcCost}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            <Input
              nameInput="rowCost"
              title="Other Skip Rate per record"
              placeHolder="$"
              type="number"
              step="0.001"
              min="0"
              onChange={formik.handleChange}
              value={formik.values.rowCost}
              error={formik.errors.rowCost}
              isTouched={formik.touched.rowCost}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="styled-modal__container__form__input">
        <FormControlLabel
          control={(
            <Checkbox
              name="isBlacklistFree"
              onChange={formik.handleChange}
              checked={formik.values.isBlacklistFree}
              color="primary"
            />
          )}
          label="Is Blacklist Feature Free"
        />
        {
          props.isChangeSubscriptiopn && (
            <FormControlLabel
              control={(
                <Checkbox
                  name="isSubscriptionFree"
                  onChange={formik.handleChange}
                  checked={formik.values.isSubscriptionFree}
                  color="primary"
                />
              )}
              label="Is Subscription Feature Free"
            />
          )
        }
        </div>
        <div className="styled-modal__container__buttons-area">
          <GhostButton onClick={props?.onCloseModal}>
            <p className="text-3">Cancel</p>
          </GhostButton>
          <Button type="submit">
            <h4>Save changes</h4>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeCostModal;
