import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

export const Overlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dim effect
  zIndex: 999, // Behind the modal but above other content
});

export const CarouselWrapper = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000, // Above the overlay
  width: '80%',
  maxWidth: '800px',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
  padding: '20px',
//   position: 'relative',
});

export const CloseButton = styled(CloseIcon)({
  position: 'absolute',
  zIndex: 1001, // Above the modal
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  color: '#555',
  '&:hover': {
    color: '#000',
  },
});
