import Papa from 'papaparse';

interface ICsvData {
  [key: string ]: string;
}

type DDType = {
  records: number;
  fields: Array<string>;
  data: Array<ICsvData>;
};

export const parseFile = async (file: File) => {
  const data: DDType = await new Promise((resolve) => {
    const onComplete = (results: Papa.ParseResult<unknown>) => {
      return resolve({
        fields: results.meta.fields as Array<string>,
        records: results.data.length + 1,
        data: results.data as Array<ICsvData>,
      });
    };

    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete(results) {
        onComplete(results);
      },
    });
  });

  return data;
};

export const createCsvFile = async (ICsvData: ICsvData[], filter?: (row: ICsvData) => ICsvData)
: Promise<string> => {
  return Papa.unparse(filter ? ICsvData.map(filter) : ICsvData);
};

export const convertToBase64 = (csvFile: string) => {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(csvFile);

  let binaryString = '';
  for (let i = 0; i < encodedData.length; i += 1024) {
    binaryString += String.fromCharCode(...Array.from(encodedData.slice(i, i + 1024)));
  }

  return window.btoa(binaryString);
};

export const convertFromBase64 = (base64String: string) => {
  const binaryString = window.atob(base64String); // Decode Base64 to binary string
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new TextDecoder().decode(bytes);
};
