import React, { useState } from 'react';
import CarouselComponent from '../CarousalComponent';
import { CarouselWrapper, CloseButton, Overlay } from './styles';
import userThunks from '../../store/user/userThunk';
import { useAppDispatch } from '../../store/hooks';

interface ICarouselModalProps {
  setShowModal: (value: boolean) => void;
}

const CarouselModal: React.FC<ICarouselModalProps> = ({ setShowModal }) => {
  const dispatch = useAppDispatch();

  const handleClose = async () => {
    setShowModal(false);
    await dispatch(userThunks.changeInfoUser({ showSiteNavigation: false })).unwrap();
  };
  return (
    <>
      <Overlay />
      <CarouselWrapper>
        <CloseButton onClick={handleClose} />
        <CarouselComponent />
      </CarouselWrapper>
    </>
  );
};

export default CarouselModal;
