import { useState } from 'react';
import { Box, Grid, Card, CardActionArea, Typography, Button } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import CarouselModal from '../../components/CarousalModal/CarousalModal';

const embedUrls = [
  {
    url: 'https://share.synthesia.io/embeds/videos/fcddad87-6435-49ae-83f6-434de2a98b3d',
    title: 'Website Overview',
  },
  {
    url: 'https://share.synthesia.io/embeds/videos/db81fa1c-472a-4e03-bd1d-2442af27cba4',
    title: 'Dashboard Overview',
  },
  {
    url: 'https://share.synthesia.io/embeds/videos/9c58f9c6-b463-47c1-8042-86c2ce06920c',
    title: 'How to Skiptrace',
  },
];

const HowToUseSitePage = () => {
  const [showNavigationModal, setShowNavigationModal] = useState(false);

  const handleOpenModal = () => {
    setShowNavigationModal(true);
  };
  return (
    <Box sx={{ px: '30px', py: '25px', width: '100%', display: 'flex', flexDirection: 'column', gap: 4 }}>
      <PageTitle
        title="How To Use Site"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Button
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="contained"
          onClick={handleOpenModal}
        >
          SkipTrace Guide
        </Button>
      </PageTitle>
      {showNavigationModal && <CarouselModal setShowModal={setShowNavigationModal} />}
      <Card sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5 }}>
        <Grid container spacing={2}>
          {embedUrls.map((item, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <Card
                sx={{
                  borderRadius: '15px',
                  overflow: 'hidden',
                  height: { xs: '250px', sm: '350px' },
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  '&:hover .title-overlay': {
                    opacity: 0,
                    zIndex: -1,
                  },
                }}
              >
                <CardActionArea
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Box
                    className="title-overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      zIndex: 1,
                      p: 1,
                      opacity: 1,
                      transition: 'opacity 0.5s ease',
                    }}
                  >
                    <Typography variant="h6" sx={{ ml: 2 }}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Box
                    component="iframe"
                    src={item.url}
                    title={item.title}
                    allowFullScreen
                    allow="encrypted-media; fullscreen"
                    loading="lazy"
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      display: 'block',
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Box>
  );
};

export default HowToUseSitePage;
