import React, { useState } from 'react';
import {
  Radio,
  RadioGroup as MUIRadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';

type RadioOptionType = {
  label: string;
  value: string;
  disabled?: boolean;
};

type RadioGroupType = {
  options: RadioOptionType[];
  onChange?: (value: string) => void;
  label?: string;
  defaultValue?: string;
};

const RadioGroup: React.FC<RadioGroupType> = ({
  label,
  options,
  defaultValue,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <FormControl>
      <FormLabel color="primary">{label}</FormLabel>
      <MUIRadioGroup value={selectedValue} onChange={handleChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={option.disabled || false}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
