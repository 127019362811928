import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Box, Typography } from '@mui/material';
import userThunks from '../../../../store/user/userThunk';
import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';
import { useAppDispatch } from '../../../../store/hooks';
import { signupSchema } from '../../../../types/validationSchemes';
import { checkIsEmailErrorMessage, checkIsCouponErrorMessage } from '../../../../utils/errorCheckHelper';
import track from '../../../../utils/googleAnalyticsEvents';

const SignUpPagePart1 = () => {
  const inputCode = new URLSearchParams(window.location.search).get('code') != null ? new URLSearchParams(window.location.search).get('code')!.toString() : '';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: { email: '', password: '', code: inputCode, agreement: false },
    validationSchema: signupSchema,
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: async (values, { setErrors }) => {
      try {
        await dispatch(userThunks.signUp(values)).unwrap();
        track.signupEvent();
        navigate('/sign-up/part-2');
      } catch (err) {
        if (checkIsEmailErrorMessage(err)) {
          setErrors({ email: err });
        } else if (checkIsCouponErrorMessage(err)) {
          setErrors({ code: err });
        } else {
          toast.error(err);
        }
      }
    },
  });

  return (
    <Box component="form" sx={{ width: '100%', maxWidth: '500px' }} onSubmit={formik.handleSubmit}>
      <Box className="styled-signup-page__container">
        <Box className="styled-signup-page__container__title-area">
          <Typography className="styled-signup-page__container__title-area__title">Easy Button Skip Trace</Typography>
          <Typography className="text-2 styled-signup-page__container__title-area__description">It`s time for ACCURATE Prospect Data.</Typography>
        </Box>

        <Box className="styled-signup-page__container__main-content">
          <Box className="styled-signup-page__container__main-content__inputs-area">
            <Input
              nameInput="email"
              placeHolder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              isTouched={formik.touched.email}
              onBlur={formik.handleBlur}
            />
            <Input
              nameInput="password"
              placeHolder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.errors.password}
              onBlur={formik.handleBlur}
              password
              displayIcon
              displayPasswordCounter
            />
          </Box>

          <Box className="styled-signup-page__container__check-area">
            <Checkbox
              name="agreement"
              onChange={formik.handleChange}
              checked={formik.values.agreement}
            />

            <Box className="styled-signup-page__container__check-area__links-area">
              <Typography className="text-4">I agree to</Typography>
              <Link to="#" className="label-text styled-signup-page__container__check-area__links-area__link">Terms of Use</Link>
              <Typography className="text-4">and</Typography>
              <Link to="#" className="label-text styled-signup-page__container__check-area__links-area__link">Privacy Policy</Link>
            </Box>
          </Box>
        </Box>

        <Button sx={{ py: 2, borderRadius: 2 }} variant="contained" type="submit" fullWidth disabled={!formik.isValid}>
          <h4>Sign up</h4>
        </Button>

      </Box>
    </Box>
  );
};

export default SignUpPagePart1;
