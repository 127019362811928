import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { CarouselItemProps } from './types';
import step1 from '../../assets/step1.png';
import step2 from '../../assets/step2.png';
import step3 from '../../assets/step3.png';
import step4 from '../../assets/step4.png';
import step7 from '../../assets/step7.png';
import step8 from '../../assets/step8.png';
import step9 from '../../assets/step9.png';
import step10 from '../../assets/step10.png';
import step11 from '../../assets/step11.png';
import step12 from '../../assets/step12.png';
import step13 from '../../assets/step13.png';
import step14 from '../../assets/step14.png';
import step15 from '../../assets/step15.png';
import step16 from '../../assets/step16.png';
import step17 from '../../assets/step17.png';
import step18 from '../../assets/step18.png';

const stepData = [
  { img: step1, description: 'Click on Skip Trace' },
  { img: step2, description: 'Click on Upload File' },
  { img: step3, description: 'Select the file you wish to skiptrace from your file upload menu' },
  { img: step17, description: 'Select which skiptrace type you are wanting to use' },
  { img: step18, description: 'Click on REVIEW ORDER' },
  { img: step4, description: 'Map all input data' },
  { img: step7, description: 'Click on APPLY MAPPING' },
  { img: step8, description: 'Click on REVIEW ORDER' },
  { img: step9, description: 'Select "Yes" if you wish to include emails in your skiptrace' },
  { img: step10, description: 'Select "Yes" if you would like to remove all known litigator phone numbers from your output file' },
  { img: step11, description: 'Check that you agree with the Fulfillment Policy' },
  { img: step12, description: 'Click on SUBMIT' },
  { img: step13, description: 'See your order in your dashboard that will have the status as "uploaded"' },
  { img: step14, description: 'When your order is received by our internal system, and has begun skiptracing, the status will change to "Processing"' },
  { img: step15, description: 'Once your list is complete, the status will change to "Successful"' },
  { img: step16, description: 'Your list is now complete and you can download your skiptraced list by clicking the file download button here!' },
];

const useStyles = makeStyles({
  paper: {
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    maxHeight: '500px',
    objectFit: 'contain',
  },
  div: {
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const items: CarouselItemProps[] = stepData.map((step, index) => ({
  name: `Step ${index + 1}`,
  description: step.description,
  img: step.img,
}));

const CarouselComponent: React.FC = () => {
  return (
    <Carousel autoPlay={false} animation="slide">
      {items.map((item, index) => (
        <CarouselItem key={index} item={item} />
      ))}
    </Carousel>
  );
};

const CarouselItem: React.FC<{ item: CarouselItemProps }> = ({ item }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <h2>{item.name}</h2>
      <div className={classes.div}>
        <img src={item.img} alt={item.name} className={classes.image} />
      </div>
      <p>{item.description}</p>
    </Paper>
  );
};

export default CarouselComponent;
