import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { Box, Card, Button, Typography, useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import DragAndDropArea from '../../components/DragAndDropArea/DragAndDropArea';
import PageTitle from '../../components/PageTitle';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { FirstTimeAlert } from '../../components/Alerts';
import { paymentApi } from '../../api/services/userApi';
import config from '../../config';
import { useAppSelector } from '../../store/hooks';
import track from '../../utils/googleAnalyticsEvents';

const ImportListPage = () => {
  const user = useAppSelector((state) => state.userSlice.user);
  const userSubscription = user.subscription;
  const [isSingleSkipTrace, setisSingleSkipTrace] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const theme = useTheme();

  useEffect(() => {
    if (sessionId) {
      track.subscriptionEvent();
    }
  }, [sessionId]);

  const onAddSingleSkipTrace = () => {
    setisSingleSkipTrace(true);
  };

  const onCancelSingleSkipTrace = () => {
    setisSingleSkipTrace(false);
  };

  const onSubscription = async () => {
    try {
      if (!config.subscriptionPlans.starter.id) return;
      const res = await paymentApi.checkoutTrialSubscription(
        config.subscriptionPlans.starter.id,
        location.pathname,
      );
      if (res.status === StatusCodes.OK) {
        const link = document.createElement('a');
        link.href = res.data.data?.session.url || '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        px: '30px',
        py: '25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box>
        <FirstTimeAlert />
        {isSingleSkipTrace ? (
          <PageTitle title="Skip Trace" />
        ) : (
          <PageTitle
            title="Skip Trace"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Button
              endIcon={<PlusIcon fill="white" />}
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'capitalize',
              }}
              variant="contained"
              onClick={() => onAddSingleSkipTrace()}
              disabled={!userSubscription?.id}
            >
              Add Single Trace
            </Button>
          </PageTitle>
        )}
      </Box>
      <Card sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5, position: 'relative' }}>
        <Box
          sx={!userSubscription?.id ? {
            filter: 'blur(15px)',
            pointerEvents: 'none',
          } : {}}
        >
          {isSingleSkipTrace && (
            <Button
              startIcon={<ArrowLeftIcon fill={theme.palette.primary.main} />}
              sx={{
                px: 2,
                borderRadius: 2,
                textTransform: 'capitalize',
              }}
              onClick={() => onCancelSingleSkipTrace()}
              disabled={!userSubscription?.id}
            >
              Back
            </Button>
          )}
          <DragAndDropArea
            isSingleSkipTrace={isSingleSkipTrace}
          />
        </Box>
        {!userSubscription?.id && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LockIcon color="primary" sx={{ fontSize: '120px' }} />
            <Box display="flex" alignItems="center" gap={1}>
              <Typography>
                You need an active subscription to access these features.
              </Typography>
              <Button onClick={onSubscription} variant="contained">
                Subscribe
              </Button>
            </Box>
            {
              !user.hasUsedTrial && (
              <Typography>
                Subscribe to start a free trial of 7 days.
              </Typography>
              )
            }
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default ImportListPage;
