export enum SkipTraceFileTypeENUM {
  OWNER_MAILING_ADDRESS = 'Owner Name / Mailing Address Skip Trace',
  BUSSINESS = 'Business Skip Trace',
  PROPERTY_ADDRESS = 'Property Address Skip Trace',
  EMAIL = 'Reverse Email Skip Trace',
  PHONE = 'Reverse Phone Skip Trace',
  BLACKLISTED = 'Litigator Scrub',
  DNC = 'DNC Scrub',
}

interface ISkipTraceFileHeaders {
  [key:string] : string[];
}
interface ISkipTraceFieldMapping {
  [key: string]: Record<string, string>;
}

export const SkipTraceFileHeaders: ISkipTraceFileHeaders = {
  [SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS]: [
    'FirstName',
    'LastName',
    'RecipientAddress',
    'RecipientCity',
    'RecipientState',
    'RecipientPostalCode',
    'PropertyAddress',
    'PropertyCity',
    'PropertyState',
    'PropertyPostalCode',
  ],
  [SkipTraceFileTypeENUM.BUSSINESS]: [
    'Owner',
    'FN',
    'LN',
    'OA',
    'OC',
    'OS',
    'OZ',
    'PID',
    'SA',
    'SC',
    'SS',
    'SZ',
    'LSF',
    'BSF',
    'LU',
    'ALV',
    'AIV',
    'AV',
    'AY',
    'MI',
    'ZD',
  ],
  [SkipTraceFileTypeENUM.PROPERTY_ADDRESS]: ['ID', 'Address', 'City', 'State', 'Zip'],
  [SkipTraceFileTypeENUM.EMAIL]: ['Email'],
  [SkipTraceFileTypeENUM.PHONE]: ['Id', 'Phone Number'],
  [SkipTraceFileTypeENUM.BLACKLISTED]: [
    'phone_1_number',
    'phone_2_number',
    'phone_3_number',
    'phone_4_number',
    'phone_5_number',
  ],
  [SkipTraceFileTypeENUM.DNC]: [
    'phone_1_number',
    'phone_2_number',
    'phone_3_number',
    'phone_4_number',
    'phone_5_number',
  ],
};

export const SkipTraceFileRequiredHeaders: ISkipTraceFileHeaders = {
  [SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS]: [
    'FirstName',
    'LastName',
    'RecipientAddress',
    'RecipientCity',
    'RecipientState',
    'RecipientPostalCode',
  ],
  [SkipTraceFileTypeENUM.BUSSINESS]: [
    'LN',
    'OA',
    'OC',
    'OS',
    'OZ',
  ],
  [SkipTraceFileTypeENUM.PROPERTY_ADDRESS]: ['Address', 'City', 'State', 'Zip'],
  [SkipTraceFileTypeENUM.EMAIL]: ['Email'],
  [SkipTraceFileTypeENUM.PHONE]: ['Phone Number'],
  [SkipTraceFileTypeENUM.BLACKLISTED]: [
    'phone_1_number',
    'phone_2_number',
    'phone_3_number',
    'phone_4_number',
    'phone_5_number',
  ],
  [SkipTraceFileTypeENUM.DNC]: [
    'phone_1_number',
    'phone_2_number',
    'phone_3_number',
    'phone_4_number',
    'phone_5_number',
  ],
};

export const SkipTraceFieldMapping: ISkipTraceFieldMapping = {
  [SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS]: {
    FirstName: 'First Name',
    LastName: 'Last Name',
    RecipientAddress: 'Mailing Address',
    RecipientCity: 'Mailing City',
    RecipientState: 'Mailing State',
    RecipientPostalCode: 'Mailing PostalCode',
  },
  [SkipTraceFileTypeENUM.BUSSINESS]: {
    LN: 'Name of Business/Trust',
    OA: 'Mailing Address',
    OC: 'Mailing City',
    OS: 'Mailing State',
    OZ: 'Mailing Zipcode',
  },
  [SkipTraceFileTypeENUM.PROPERTY_ADDRESS]: {
    Address: 'Property Address',
    City: 'Property City',
    State: 'Property State',
    Zip: 'Property Zip',
  },
  [SkipTraceFileTypeENUM.EMAIL]: {
    Email: 'Email',
  },
  [SkipTraceFileTypeENUM.PHONE]: {
    'Phone Number': 'Phone Number',
  },
  [SkipTraceFileTypeENUM.BLACKLISTED]: {
    phone_1_number: 'Phone No. 1',
    phone_2_number: 'Phone No. 2',
    phone_3_number: 'Phone No. 3',
    phone_4_number: 'Phone No. 4',
    phone_5_number: 'Phone No. 5',
  },
  [SkipTraceFileTypeENUM.DNC]: {
    phone_1_number: 'Phone No. 1',
    phone_2_number: 'Phone No. 2',
    phone_3_number: 'Phone No. 3',
    phone_4_number: 'Phone No. 4',
    phone_5_number: 'Phone No. 5',
  },
};

export const isValidFileHeaders = (skipTraceFields: string[], fileFields: string[]) => {
  return fileFields.every(
    (field, index) => skipTraceFields[index] &&
    skipTraceFields[index].toLowerCase().trim() === field.toLowerCase().trim(),
  );
};
