/* eslint-disable */ import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledDragAndDropArea from './DragAndDropArea.styles';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ErrorMappingModal from '../ErrorMappingModal/ErrorMappingModal';
import ErrorBalanceModal from '../ErrorBalanceModal/ErrorBalanceModal';
import ErrorCountRecordsModal from '../ErrorCountRecordsModal/ErrorCountRecordsModal';
import FileUpload from '../FileUpload/FileUpload';
import {
  setLoading,
  setUploadFile,
  unsetLoading,
  unsetUploadFile,
  setDiscount,
  setFileMetadata,
} from '../../store/order/orderSlice';
import { parseFile, createCsvFile, convertToBase64 } from '../../utils/parsingFileHelper';
import { getUploadFileName } from '../../utils/getUploadFileName';
import AddSkipTraceModal from '../AddSkipTraceModal';
import MappingData from '../MappingData';
import {
  SkipTraceFileHeaders,
  SkipTraceFileTypeENUM,
  isValidFileHeaders,
  SkipTraceFileRequiredHeaders,
  SkipTraceFieldMapping,
} from "../../utils/skipTraceFileHeaders";
import Bill from '../Bill/Bill';
import config from '../../config';
import RadioGroup from '../RadioGroup';

type PropsType = {
  onClick?: () => void;
  className?: string;
  isSingleSkipTrace?: boolean;
} & React.PropsWithChildren;

interface IObject {
  [key:string]: string;
}
interface IFileInfo {
  name: string;
  size: number;
  fields: string[];
  records: number;
  data: IObject[];
}

const initialFileInfo: IFileInfo = { name:'', size: 0, records: 0, fields: [], data: [] }

const DragAndDropArea: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(
    (state) => state.orderSlice.addition.isLoading
  );
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const userSubscription = userInfo.subscription;
  const currentDiscount = userInfo.discount ? userInfo.discount : 0;
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isOpenBillModal, setIsOpenBillModal] = useState(false);
  const [fileInfo, setFileInfo] = useState<IFileInfo>(initialFileInfo);
  const [isOpenErrorMappingModal, setIsOpenErrorMappingModal] = useState(false);
  const [isOpenErrorBalanceModal, setIsOpenErrorBalanceModal] = useState(false);
  const [isOpenErrorCountRecordsModal, setIsOpenErrorCountRecordsModal] =
    useState(false);
  const [isOpenAddSkipTraceModal, setIsOpenAddSkipTraceModal] = useState(false);
  const [isOpenMapping, setIsOpenMapping] = useState(false);
  const [skipTraceType, setSkipTraceType] = useState<string>(SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS);
  const skipTraceFields = SkipTraceFileHeaders[skipTraceType];
  const requiredFields = SkipTraceFileRequiredHeaders[skipTraceType];
  const requiredFieldsMappings = SkipTraceFieldMapping[skipTraceType];
  useEffect(()=> {
    dispatch(setDiscount({ discount: currentDiscount }));
    dispatch(unsetUploadFile());
  },[])

  const isLocked = (fileType: string) => {
    if (!userSubscription?.id) {
      return true;
    }
    if (fileType === SkipTraceFileTypeENUM.BLACKLISTED || fileType === SkipTraceFileTypeENUM.DNC) {
      return !userInfo.isBlacklistFree;
    }
    if (fileType === SkipTraceFileTypeENUM.EMAIL || fileType === SkipTraceFileTypeENUM.PHONE) {
      return ['starter', 'growth'].includes(userSubscription?.type.toLowerCase() || '');
    }
    return false;
  };

  const options = Object.values(SkipTraceFileTypeENUM).map((fileType) => (
    { label: fileType, value: fileType, disabled: isLocked(fileType) }
  ));
  
  const handleRadioChange = (value: string) => {
    setSkipTraceType(value);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const isActiveEvent = e.type === 'dragenter' || e.type === 'dragover';
    if (!isFileUpload) {
      if (isActiveEvent) {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (!isFileUpload) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        if (e.dataTransfer.files[0].name.endsWith('.csv')) {
          handleUploadeFile(e.dataTransfer.files[0]);
        }
      }
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsOpenErrorCountRecordsModal(false);
    setIsOpenErrorBalanceModal(false);
    if (e.target.files && e.target.files[0]) {
      const currentFile = e.target.files[0];
      if (currentFile.name.endsWith('.csv')) {
        handleUploadeFile(currentFile)
      }
    }
  };

  const handleUploadeFile = async (file: File) => {
    try {
      dispatch(setLoading());
      const { records, fields, data } = await parseFile(file);
      if( records <= 1 ){
        toast.error("File should not be empty.")
      }
      else{
        setFileInfo({ name: file.name, size: file.size, records, fields, data });
        setIsFileUpload(true);
      }
    }
    catch (error){
      toast.error(error)
    } finally {
      dispatch(unsetLoading());
    }
  }

  const onUploadFile_ = async (name: string, csvBase64: string, records: number, fields: string[]) => {
    try {
    dispatch(
      setUploadFile({
        name: getUploadFileName(name),
        csvBase64,
        records,
        fields,
        skipTraceType,
      })
    );
    setIsOpenErrorMappingModal(false);
    setIsOpenErrorCountRecordsModal(false);
    setIsOpenErrorBalanceModal(false);
    } catch (error) {
      toast.error(error)
    }
  }

  const handleSkipTraceType = async (dnc: boolean, mappings: IObject) => {
    debugger;
    dispatch(setFileMetadata({ blacklist_columns: Object.values(mappings), dnc }));
    const csvFile = await createCsvFile(fileInfo.data);
    const csvBase64 = convertToBase64(csvFile);
    onUploadFile_(fileInfo.name, csvBase64, fileInfo.records, fileInfo.fields);
    setIsOpenBillModal(true);
  };

  const onApplyMapping = async (mappings: IObject) => {
    try {
      const { name, records, data, fields } = fileInfo
      if(records > 1 ){
        if(skipTraceType === SkipTraceFileTypeENUM.BLACKLISTED){
          await handleSkipTraceType(false, mappings);
          return;
        }
        if(skipTraceType === SkipTraceFileTypeENUM.DNC){
          await handleSkipTraceType(true, mappings);
          return;
        }
        const filter = (row: IObject) => {
          const newRow: IObject = {};
          skipTraceFields.forEach(newField => {
            if (mappings[newField]) {
              newRow[newField] = row[mappings[newField]];
            }else{
              newRow[newField] = ""
            }
          });
          return newRow;
        }
        const csvFile = await createCsvFile(data, filter)
        const csvBase64 = convertToBase64(csvFile);

        onUploadFile_(name, csvBase64, records, skipTraceFields);
        setIsOpenBillModal(true);
      }
    } catch (error) {
      console.error("here", error);
      toast.error(error)
    }
  }

  const deleteFile = async () => {
    try {
      dispatch(unsetUploadFile());
      setIsFileUpload(false);
      setFileInfo(initialFileInfo);
      setIsOpenMapping(false)
      setSkipTraceType(SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS)
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickReviewOrder = async () => {
    var cost = 0;
    const { name, records, data, fields } = fileInfo
    if (fields.length < requiredFields.length){ 
      setIsOpenErrorMappingModal(true);
      return
    }
    if (SkipTraceFileTypeENUM.BUSSINESS === skipTraceType) {
      cost = (records - 1) * userInfo.llcCost;
    } else if (
      currentDiscount !== 0 &&
      userInfo.code === config.firstTimeCoupon.code &&
      (records - 1) > config.firstTimeCoupon.upto
    ) {
      const discountAmount = config.firstTimeCoupon.upto * userInfo.rowCost * (currentDiscount / 100);
      cost = (records - 1) * userInfo.rowCost - discountAmount;
    }
    else {
      cost = (records - 1) * userInfo.rowCost * (1 - currentDiscount);
    }
    if (userInfo.balance < cost) {
      setIsOpenErrorBalanceModal(true);
    }
    else if (!isValidFileHeaders(skipTraceFields, fields)) {
      setIsOpenMapping(true)
    }
    else{
      const csvFile = await createCsvFile(data)
      const csvBase64 = convertToBase64(csvFile);
      onUploadFile_(name, csvBase64, records, fields);
      setIsOpenBillModal(true);
    }
  };
  const handleCloseBillModal = () => {
    setIsOpenBillModal(false);
  };

  const onClickCancelBalanceModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorBalanceModal(false);
  };

  const onCurrentClickBalanceModal = () => {
    navigate('/payments/enter-amount');
  };

  const onClickCancelRecordsModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorCountRecordsModal(false);
  };

  const onClickCancelMappingModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorMappingModal(false);
  };

  const onClickCancelAddSkipTraceModal = () => {
    setIsOpenAddSkipTraceModal(false);
  };

  const onClickOpenAddSkipTraceModal = () => {
    setIsOpenAddSkipTraceModal(true);
  };

  const handleAddSkipTrace = async (values: IObject) => {
    const csvFile = await createCsvFile([values])
    const csvBase64 = convertToBase64(csvFile);
    onUploadFile_('single-skip-trace.csv', csvBase64, 2, skipTraceFields);
    setIsOpenAddSkipTraceModal(false);
    setIsOpenBillModal(true);
  }

  return (
    <StyledDragAndDropArea
      onClick={props?.onClick}
      className={props?.className ?? ''}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      isDragActive={isDragActive && !isFileUpload}
    >
      {
        props.isSingleSkipTrace ? (
          <>
            <div className="styled-drag-and-drop-area__text-area">
              <RadioGroup label='Select Skiptrace Type' options={options} onChange={handleRadioChange} defaultValue={skipTraceType} />
            </div>
            <Button variant="contained" sx={{ py: 1.5, borderRadius: 2 }} onClick={onClickOpenAddSkipTraceModal}>
              <h4>Add</h4>
            </Button>
          </>
        ) : (
          <FileUpload
            isLoading={isLoading}
            isFileUpload={isFileUpload}
            fileInfo={fileInfo}
            handleChange={handleChange}
            deleteFile={deleteFile}
          />
        )
      }

      {!isOpenMapping && isFileUpload && (
        <>
        <div className="styled-drag-and-drop-area__text-area">
          <RadioGroup label='Select Skiptrace Type' options={options} onChange={handleRadioChange} defaultValue={skipTraceType} />
        </div>
        <Button endIcon={<ArrowRightIcon fill="white"/>} variant="contained" sx={{ py: 1.5, borderRadius: 2 }} onClick={onClickReviewOrder}>
          <h4>Review Order</h4>
        </Button>
        </>
      )}

      {isOpenMapping && (
        <MappingData
          sourceFields={fileInfo.fields}
          destinationFields={requiredFields}
          onApplyMapping={onApplyMapping}
          fieldMappings={requiredFieldsMappings}
        />
      )}

      {isOpenBillModal ? (
        <Bill
        open={isOpenBillModal}
        onClose={handleCloseBillModal}
        />
      ) : null}

      {isOpenErrorMappingModal ? (
        <ErrorMappingModal
          handleChange={handleChange}
          onCloseModal={onClickCancelMappingModal}
        />
      ) : null}

      {isOpenErrorBalanceModal ? (
        <ErrorBalanceModal
          onCloseModal={onClickCancelBalanceModal}
          onClick={onCurrentClickBalanceModal}
        />
      ) : null}

      {isOpenErrorCountRecordsModal ? (
        <ErrorCountRecordsModal
          handleChange={handleChange}
          onCloseModal={onClickCancelRecordsModal}
        />
      ) : null}

      {isOpenAddSkipTraceModal ? (
        <AddSkipTraceModal
          skipTraceType={skipTraceType}
          onUploadFile={handleAddSkipTrace}
          onCloseModal={onClickCancelAddSkipTraceModal}
        />
      ) : null}
    </StyledDragAndDropArea>
  );
};

export default DragAndDropArea;
